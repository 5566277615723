import React, { useState } from "react";
import { AvatarType } from "../types/AvatarType";
import "../styles/Avatars.css";

const ShareIcon = () => (
  <svg
    className="system7-share-icon"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Retro globe icon */}
    <circle
      cx="16"
      cy="16"
      r="14"
      fill="white"
      stroke="black"
      strokeWidth="2"
    />
    <path d="M16 2C16 16 16 30 16 30" stroke="black" strokeWidth="2" />
    <path d="M2 16C16 16 30 16 30 16" stroke="black" strokeWidth="2" />
    <path d="M4 8C12 8 20 8 28 8" stroke="black" strokeWidth="2" />
    <path d="M4 24C12 24 20 24 28 24" stroke="black" strokeWidth="2" />
    <path d="M8 4C8 12 8 20 8 28" stroke="black" strokeWidth="2" />
    <path d="M24 4C24 12 24 20 24 28" stroke="black" strokeWidth="2" />
  </svg>
);

interface AvatarShareProps {
  avatar: AvatarType;
}

const AvatarShare = ({ avatar }: AvatarShareProps) => {
  const [showMenu, setShowMenu] = useState(false);

  const shareUrl = window.location.href;
  const shareTitle = `Schau dir meinen #vcfzh25 Avatar '${avatar.name}' an:`;
  //const shareText = `${avatar.description}\n\nCreated at VCF Zürich 2025`;

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: `${shareTitle}\n${shareUrl}`,
          url: shareUrl,
        });
      } catch (error: any) {
        if (error.name !== "AbortError") {
          setShowMenu(true);
        }
      }
    } else {
      setShowMenu(true);
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareUrl);
    setShowMenu(false);
  };

  const shareViaService = (service: any) => {
    const encodedUrl = encodeURIComponent(shareUrl);
    const encodedTitle = encodeURIComponent(shareTitle);

    const urls = {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
    };

    // @ts-ignore
    window.open(urls[service], "_blank", "width=600,height=400");
    setShowMenu(false);
  };

  return (
    <>
      <div className="system7-share-container">
        <button onClick={handleShare} className="system7-back-button">
          <span className="system7-share-label">Avatar Teilen</span>
        </button>
      </div>

      {showMenu && (
        <>
          <div
            className="system7-share-overlay"
            onClick={() => setShowMenu(false)}
          />
          <div className="system7-share-menu">
            <img
              src="/assets/images/vcfzh25_qr.png"
              alt="Share via QR Code"
              className="system7-avatar-image"
            />
            <button onClick={handleCopyLink} className="system7-back-button">
              <svg className="system7-share-icon" viewBox="0 0 32 32">
                {/* Simple link icon */}
                <path
                  d="M12 16H20M8 12L24 20M24 12L8 20"
                  stroke="black"
                  strokeWidth="2"
                />
              </svg>
              <span>Link kopieren</span>
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default AvatarShare;
