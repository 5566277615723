import React from "react";
import "../styles/About.css";

const About = () => {
  return (
    <div className="about">
      <h1>About</h1>
      <p>Master's Thesis Project Page by Dominik Schoch.</p>
      <p>
        Lucerne School of Design, Film and Art. Master of Arts in Design,
        Digital Ideation.
      </p>
      <p>
        Contact:{" "}
        <a href="mailto:dominik.schoch@stud.hslu.ch">
          dominik.schoch@stud.hslu.ch
        </a>
      </p>
      <h2>Datenschutz</h2>
      <p>
        Diese Website verwendet Vercel Web Analytics, um zu verstehen, wie
        Besucher interagieren. Vercel Web Analytics verwendet keine Cookies und
        sammelt keine persönlichen Daten. Es liefert aggregierte Informationen,
        um die Leistung dieser Website und die Benutzererfahrung zu verbessern.
      </p>
      <p>
        <a
          href="https://vercel.com/docs/analytics/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Mehr informationen
        </a>
      </p>
    </div>
  );
};

export default About;
