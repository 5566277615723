import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { AvatarType } from "../types/AvatarType";
import "../styles/Avatars.css";
import AvatarShare from "./AvatarShare";

const AvatarDetail = () => {
  const { uuid } = useParams(); // Get the UUID from the URL
  const [avatar, setAvatar] = useState<AvatarType | null>(null);
  const [loading, setLoading] = useState(true);

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  useEffect(() => {
    const fetchAvatarDetails = async () => {
      try {
        const response = await api.get<AvatarType>(
          `/api/avatar/detail/${uuid}`,
        );
        setAvatar(response.data); // Store the avatar data in state
        setLoading(false); // Stop loading
      } catch (error) {
        toast.error(`Error fetching avatar`);
        setLoading(false);
      }
    };

    fetchAvatarDetails();
  }, [uuid]);

  if (loading) return <div>Loading...</div>;
  if (!avatar) return null;

  return (
    <div className="system7-container">
      <ToastContainer position="bottom-center" autoClose={4000} />
      <Link to="/exhibits/vcfzh25" className="system7-container-link">
        « Zurück zu den Avataren
      </Link>
      <div className="system7-details-container">
        <div className="system7-details-window">
          <div className="system7-titlebar">
            <div className="system7-titlebar-content">
              <div className="system7-close-box"></div>
              <div className="system7-window-title-container">
                <span className="system7-window-title">{avatar.name}</span>
              </div>
            </div>
          </div>

          <div className="system7-details-content">
            {avatar.imageUrl ? (
              <img
                src={avatar.imageUrl}
                alt={`${avatar.name}'s Avatar`}
                className="system7-details-image"
              />
            ) : (
              <div className="system7-no-image">Image not available</div>
            )}

            <div className="system7-details-description">
              {avatar.description}
            </div>
            <AvatarShare avatar={avatar} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvatarDetail;
