import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "../styles/Avatars.css";
import { CreateAvatarResponseType } from "../types/CreateAvatarResponseType";
import { CreateAvatarType } from "../types/CreateAvatarType";

const AvatarForm = () => {
  const [name, setName] = useState("");
  const [retroEra, setRetroEra] = useState("");
  const [favoriteDevice, setFavoriteDevice] = useState("");
  const [favoriteFeatures, setFavoriteFeatures] = useState("");
  const [favoriteStorage, setFavoriteStorage] = useState("");
  const [skills, setSkills] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const avatarURL = "/exhibits/vcfzh25";

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    const createAvatar: CreateAvatarType = {
      name,
      retroEra,
      favoriteDevice,
      favoriteFeatures,
      favoriteStorage,
      skills,
    };

    try {
      const response = await api.post<CreateAvatarResponseType>(
        "/api/avatar",
        createAvatar,
      );

      // Clear form
      setName("");
      setRetroEra("");
      setFavoriteDevice("");
      setFavoriteFeatures("");
      setFavoriteStorage("");
      setSkills("");

      //toast.success("Avatar created successfully!");
      navigate(`${avatarURL}/detail/${response.data.shortUuid}`);
    } catch (error) {
      toast.error(`Error creating avatar. Please try again.`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="system7-container">
      <Link to="/exhibits/vcfzh25" className="system7-container-link">
        « Zurück zu den Avataren
      </Link>
      <ToastContainer position="bottom-center" autoClose={4000} />
      <div className="system7-window">
        <div className="system7-titlebar">
          <div className="system7-titlebar-content">
            <div className="system7-close-box"></div>
            <div className="system7-window-title-container">
              <span className="system7-window-title">Neuer Avatar</span>
            </div>
          </div>
        </div>

        <div className="system7-content">
          {error && (
            <div className="system7-error">
              <p>{error}</p>
            </div>
          )}

          <form onSubmit={handleSubmit}>
            <div className="system7-form-group">
              <label className="system7-label">Name:</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                disabled={isLoading}
                className="system7-input"
              />
            </div>

            <div className="system7-form-group">
              <label className="system7-label">
                Lieblings-Retro-Computing-Ära:
              </label>
              <select
                value={retroEra}
                onChange={(e) => setRetroEra(e.target.value)}
                required
                disabled={isLoading}
                className="system7-input"
              >
                <option value="" disabled>
                  Wähle eine Ära
                </option>
                <option value="60s">1960er</option>
                <option value="70s">1970er</option>
                <option value="80s">1980er</option>
                <option value="90s">1990er</option>
                <option value="2000s">2000er</option>
              </select>
            </div>

            <div className="system7-form-group">
              <label className="system7-label">
                Bevorzugtes elektronisches Retro-Gerät:
              </label>
              <input
                type="text"
                value={favoriteDevice}
                onChange={(e) => setFavoriteDevice(e.target.value)}
                required
                disabled={isLoading}
                className="system7-input"
              />
            </div>

            <div className="system7-form-group">
              <label className="system7-label">
                Bevorzugte Geräteeigenschaften:
              </label>
              <textarea
                value={favoriteFeatures}
                onChange={(e) => setFavoriteFeatures(e.target.value)}
                required
                rows={4}
                disabled={isLoading}
                className="system7-input"
              />
            </div>

            <div className="system7-form-group">
              <label className="system7-label">Lieblings-Speichermedium:</label>
              <input
                type="text"
                value={favoriteStorage}
                onChange={(e) => setFavoriteStorage(e.target.value)}
                required
                disabled={isLoading}
                className="system7-input"
              />
            </div>

            <div className="system7-form-group">
              <label className="system7-label">Retro-Computing Skills:</label>
              <textarea
                value={skills}
                onChange={(e) => setSkills(e.target.value)}
                required
                rows={4}
                disabled={isLoading}
                className="system7-input"
              />
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="system7-button"
            >
              {isLoading ? "Lädt..." : "Avatar erstellen"}
            </button>
          </form>

          {isLoading && (
            <div className="system7-loading">
              <div className="system7-loading-box">
                Avatar wird generiert...
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AvatarForm;
