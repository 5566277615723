import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { AvatarType } from "../types/AvatarType";
import "../styles/Avatars.css";

const AvatarList = () => {
  const [avatars, setAvatars] = useState<AvatarType[]>([]);

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  useEffect(() => {
    fetchAvatars();
  }, []);

  const fetchAvatars = async () => {
    try {
      const response = await api.get<AvatarType[]>("/api/avatar");
      setAvatars(response.data);
    } catch (error) {
      toast.error(`Error fetching avatars`);
    }
  };

  const FloppyIcon = () => (
    <svg
      className="system7-floppy-icon"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2"
        y="2"
        width="28"
        height="28"
        fill="white"
        stroke="black"
        strokeWidth="2"
      />
      <rect
        x="6"
        y="2"
        width="20"
        height="6"
        fill="white"
        stroke="black"
        strokeWidth="2"
      />
      <rect x="8" y="4" width="16" height="2" fill="black" />
      <rect
        x="6"
        y="20"
        width="20"
        height="8"
        fill="white"
        stroke="black"
        strokeWidth="2"
      />
      <rect x="22" y="22" width="2" height="4" fill="black" />
    </svg>
  );

  return (
    <div className="system7-container">
      <Link
        to="/exhibits/vcfzh25/new"
        className="system7-container-link system7-container-link-mobile"
      >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
          <path d="M8 0v16M0 8h16" stroke="currentColor" strokeWidth="2" />
        </svg>
        Neuen VCFZH25-Avatar erstellen
      </Link>

      <div className="system7-avatar-grid">
        <div key="new-avatar" className="system7-link-window">
          <div className="system7-titlebar">
            <div className="system7-titlebar-content">
              <div className="system7-close-box"></div>
              <div className="system7-window-title-container">
                <span className="system7-window-title">Neuer Avatar</span>
              </div>
            </div>
          </div>

          <div className="system7-avatar-info">
            <Link to="/exhibits/vcfzh25/new" className="system7-window-link">
              <svg
                width="24"
                height="24"
                viewBox="0 0 16 16"
                fill="currentColor"
              >
                <path
                  d="M8 0v16M0 8h16"
                  stroke="currentColor"
                  strokeWidth="2"
                />
              </svg>
              <div className="system7-window-link-text">
                Neuen Avatar erstellen
              </div>
            </Link>
          </div>
        </div>

        {avatars.map((avatar) => (
          <div key={avatar.shortUuid} className="system7-avatar-window">
            <div className="system7-titlebar">
              <div className="system7-titlebar-content">
                <div className="system7-close-box"></div>
                <div className="system7-window-title-container">
                  <Link
                    to={`/exhibits/vcfzh25/detail/${avatar.shortUuid}`}
                    className="system7-title-link"
                  >
                    <span className="system7-window-title">{avatar.name}</span>
                  </Link>
                </div>
              </div>
            </div>

            <div className="system7-avatar-info">
              <Link to={`/exhibits/vcfzh25/detail/${avatar.shortUuid}`}>
                <img
                  src={avatar.imageUrl}
                  alt={`${avatar.name}'s Avatar`}
                  className="system7-avatar-image"
                />
              </Link>
            </div>
          </div>
        ))}

        <div
          key="QR-Code"
          className="system7-avatar-window system7-qrcode-window"
        >
          <div className="system7-titlebar">
            <div className="system7-titlebar-content">
              <div className="system7-close-box"></div>
              <div className="system7-window-title-container">
                <span className="system7-window-title">Share</span>
              </div>
            </div>
          </div>

          <div className="system7-avatar-info">
            <img
              src="/assets/images/vcfzh25_qr.png"
              alt="Share via QR Code"
              className="system7-avatar-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvatarList;
