import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { HistoryStepType } from "../types/HistoryStepType";
import "../styles/InterfaceMuseum.css";
import "react-toastify/dist/ReactToastify.css";

const InterfaceMuseum = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [showPopup, setShowPopup] = useState(false);

  const steps: HistoryStepType[] = [
    {
      id: 0,
      toastMessage: "Try left-clicking!",
      popUpContent:
        "The click mechanism was invented in 1968 by Douglas Engelbart.",
    },
    {
      id: 1,
      toastMessage: "Click the recycle bin to learn more!",
      popUpContent:
        "The desktop paradigm was introduced by Xerox PARC in the 1970s.",
      icon: "/assets/icons/trash.png",
    },
    {
      id: 2,
      toastMessage: "Click on the window to learn more",
      popUpContent:
        "The concept of desktop windows was introduced by Xerox PARC in the 1970s and popularized by Apple and Microsoft.",
      icon: "/assets/images/system1_window.png",
    },
  ];

  useEffect(() => {
    const step = steps.find((s) => s.id === currentStep);
    if (step) {
      const toastId = toast(step.toastMessage);
      return () => toast.dismiss(toastId);
    }
  }, [currentStep, steps]);

  const handleClick = () => {
    if (showPopup) return;
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setCurrentStep((prev) => prev + 1);
  };

  const handleRestart = () => {
    setCurrentStep(0);
    setShowPopup(false);
    toast.dismiss(); // Clear all toasts
  };

  const step = steps.find((s) => s.id === currentStep);

  return (
    <div className="interface-museum" onClick={handleClick}>
      <ToastContainer position="bottom-center" autoClose={4000} />
      {showPopup && step && (
        <div
          style={{
            position: "absolute",
            background: "white",
            padding: "20px",
            border: "1px solid black",
          }}
        >
          <p>{step.popUpContent}</p>
          <button onClick={handleClosePopup}>Close</button>
        </div>
      )}
      {step && step.id >= 1 && (
        <img
          className="trash-icon"
          src={steps[1].icon}
          alt="Step Icon"
          onClick={handleClick}
        />
      )}
      {step && step.id >= 2 && (
        <img
          className="window"
          src={steps[2].icon}
          alt="window"
          onClick={handleClick}
        />
      )}
      <button className="restart-button" onClick={handleRestart}>
        ↻
      </button>
    </div>
  );
};

export default InterfaceMuseum;
